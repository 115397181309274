import React, { useState, useEffect } from 'react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const styles = {
    container: {
        height: 'auto',
        backgroundColor: 'var(--secondary-color)',
        padding: 'var(--spacing) var(--spacing)',
    },
    title_row: {
        width: '100%',
    },
    content: {
        items: [
            'A business owner',
            'Looking to acquire more customers',
            'and you want to find them online?',
        ],
        height: 'auto',
        width: '100%',
        maxWidth: '1000px',
        // padding: 'var(--spacing) var(--spacing)',
    },
};

export default function AreYou() {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
    });

    useEffect(() => {
        // Function to update state with the new window dimensions
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
            });
        };

        // Add event listener to capture screen size on resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures effect only runs on mount and unmount

    return (
        <div
            id="are_you"
            className="d-flex flex-column justify-content-center align-items-center"
            style={styles.container}
        >
            <div
                id="areYou_title-row"
                className="d-flex justify-content-center"
            >
                <h3 className="archivo-900 are_you_titles">Are you</h3>
            </div>
            <Swiper
                modules={[Navigation, Pagination]}
                slidesPerView={windowSize.width < 996 ? 1 : 3} // Access windowSize.width correctly
                navigation={windowSize.width < 996 ? true : false}
                style={styles.content}
            >
                {styles.content.items.map((item, index) => (
                    <SwiperSlide
                        id={`content-box-${index}`}
                        key={`content-box-${index}`}
                        style={{ width: '350px' }}
                        className="d-flex flex-column align-items-center"
                    >
                        <div
                            id={`circle-${index}`}
                            style={{
                                backgroundImage: `url(/components/photos/circle-${index}.jpg)`,
                                backgroundPosition: 'center', // Fixed typo here
                                backgroundSize: 'cover',
                                borderRadius: '50%',
                                height: '250px',
                                width: '250px',
                            }}
                        ></div>
                        <div
                            id={`circle-subheader-${index}`}
                            key={`circle-subheader-${index}`}
                            className="d-flex justify-content-center align-items-center"
                        >
                            <h3
                                key={`circle-subheader-text-${index}`}
                                className="archivo-500 are_you_captions"
                                style={{ height: 'auto', maxWidth: '300px' }}
                            >
                                {item}
                            </h3>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div
                id="areYou_kicker"
                className="d-flex justify-content-center mt-auto"
            >
                <h3 className="archivo-900 are_you_titles mt-5">
                    Then we're a perfect fit
                </h3>
            </div>
        </div>
    );
}
