import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useWindowWidth } from '@react-hook/window-size';
import { motion, useScroll, useMotionValueEvent } from 'framer-motion';

export default function Nav() {
    const [mobileNavActive, setMobileNavActive] = useState(false);
    const [hidden, setHidden] = useState(false);

    const { scrollY } = useScroll();

    useMotionValueEvent(scrollY, 'change', (latest) => {
        const previous = scrollY.getPrevious();
        if (latest > previous && !mobileNavActive && latest > 150) {
            setHidden(true);
        } else {
            setHidden(false);
        }
    });

    var width = useWindowWidth();

    const nav = {
        nav: {
            position: 'sticky',
            top: 0, // Make sure to set top
            zIndex: 1000,
            backgroundColor: 'var(--primary-color)',
            width: '100%',
            borderBottom: '.1px solid var(--primary-text)',
        },
        content: {
            width: '100%',
            margin: '10px var(--spacing) 10px var(--spacing)',
        },
        logo: {
            title: {
                digital: {
                    color: 'var(--primary-text)',
                },
                theory: {
                    color: 'var(--secondary-color)',
                },
                color: 'var(--primary-text)',
                margin: '0px',
            },
        },
        links: {
            items: ['about', 'services', 'contact'],
            menu: {
                backgroundColor: 'var(--primary-text)',
                height: '1px',
                width: '20px',
                cursor: 'pointer',
            },
            text: {
                textDecoration: 'none',
                color: 'var(--secondary-color)',
                initial: {
                    margin: '0px 10px 0px 10px',
                },
                hover: {
                    scale: 1.1,
                },
            },
        },
    };

    const createNavLinks = (type) => {
        return (
            <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{
                    opacity: 1,
                    height: 'auto',
                    transition: { duration: 0.5 },
                }}
                exit={{
                    opacity: 0,
                    height: 0,
                    transition: { duration: 0.5 },
                }}
                className={`d-flex ${type === 'column' ? 'flex-column' : ''}`}
                style={{
                    gap: '20px',
                    // margin: mobileNavActive ? 'var(--spacing)' : '',
                }}
            >
                {nav.links.items.map((item, index) => {
                    return (
                        <Link
                            id={`nav-links-${item}`}
                            key={index}
                            to={`/#${item}`}
                            style={nav.links.text}
                            onClick={handleMobileNavTap}
                        >
                            <motion.p
                                variants={nav.links.text}
                                initial={'initial'}
                                whileHover={width > 768 ? 'hover' : ''}
                            >
                                {item}
                            </motion.p>
                        </Link>
                    );
                })}
            </motion.div>
        );
    };

    const handleMobileNavTap = () => {
        if (mobileNavActive === false) {
            setMobileNavActive(true);
        } else setMobileNavActive(false);
    };

    return (
        <motion.nav
            id="nav"
            className="d-flex align-items-center justify-content-center flex-wrap"
            style={nav.nav}
            variants={{
                visible: { y: 0 },
                hidden: { y: -100 },
            }}
            animate={hidden ? 'hidden' : 'visible'}
            transition={{ duration: 0.35, ease: 'easeInOut' }}
        >
            <div id="nav-content" style={nav.content}>
                <div
                    id="nav-logo-row"
                    className="d-flex justify-content-between align-items-center"
                    style={{ width: '100%' }}
                >
                    <Link to="/#home" style={{ textDecoration: 'none' }}>
                        <div
                            id="nav-logo"
                            className="d-flex align-items-center"
                            style={nav.logo}
                        >
                            <motion.h2
                                id="nav-logo-title"
                                style={nav.logo.title}
                                initial={{ opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                    transition: { duration: 1 },
                                }}
                            >
                                <span style={nav.logo.title.theory}>&lt;</span>
                                <span style={nav.logo.title.digital}>
                                    Digital
                                </span>
                                <span style={nav.logo.title.theory}>
                                    Theory
                                </span>
                                <span style={nav.logo.title.digital}>&gt;</span>
                            </motion.h2>
                        </div>
                    </Link>
                    <div
                        id="nav-links"
                        className="d-flex justify-content-end align-items-center"
                        style={{ width: '100%', height: '30px' }}
                        onClick={handleMobileNavTap}
                    >
                        {width < 769 ? (
                            <div className="" style={{ cursor: 'pointer' }}>
                                <motion.div
                                    style={nav.links.menu}
                                    initial={{ y: 5 }}
                                    animate={{
                                        rotate: mobileNavActive ? 45 : 0,
                                    }}
                                ></motion.div>
                                <motion.div
                                    style={nav.links.menu}
                                    initial={{ y: 0 }}
                                    animate={{
                                        y: mobileNavActive ? 4 : 0,
                                        rotate: mobileNavActive ? -45 : 0,
                                    }}
                                ></motion.div>
                            </div>
                        ) : (
                            createNavLinks()
                        )}
                    </div>
                </div>
                {width < 769 && (
                    <div id="nav-links-mobile" className="d-flex flex-column">
                        {mobileNavActive && createNavLinks('column')}
                    </div>
                )}
            </div>
        </motion.nav>
    );
}
