import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div
            className="d-flex flex-column align-items-center"
            style={styles.container}
        >
            <div style={styles.content}>
                <h1 style={styles.title}>Privacy Policy</h1>
                <p style={styles.effectiveDate}>Effective Date: 10/2/2024</p>
                <p style={styles.text}>
                    Thank you for your interest in Digital Theory. This Privacy
                    Policy explains how we collect, use, and protect your
                    personal information when you submit a request for more
                    information through our website.
                </p>

                <h2 style={styles.sectionTitle}>1. Information We Collect</h2>
                <p style={styles.text}>
                    When you submit a request through our website, we may
                    collect the following personal information:
                </p>
                <ul style={styles.text}>
                    <li>Name</li>
                    <li>Email address</li>
                    <li>Phone number</li>
                    <li>
                        Any additional information you choose to provide in your
                        request
                    </li>
                </ul>

                <h2 style={styles.sectionTitle}>
                    2. How We Use Your Information
                </h2>
                <p style={styles.text}>
                    We may use the information we collect for various purposes,
                    including:
                </p>
                <ul style={styles.text}>
                    <li>
                        To respond to your inquiries and provide you with the
                        requested information
                    </li>
                    <li>To improve our website and customer service</li>
                    <li>
                        To communicate with you regarding your request or other
                        products and services
                    </li>
                    <li>
                        To send periodic emails regarding updates, promotions,
                        or related information
                    </li>
                </ul>

                <h2 style={styles.sectionTitle}>
                    3. Data Protection and Security
                </h2>
                <p style={styles.text}>
                    We are committed to protecting your personal information. We
                    implement a variety of security measures to maintain the
                    safety of your personal information when you submit a
                    request. However, please be aware that no method of
                    transmission over the Internet or method of electronic
                    storage is 100% secure. While we strive to use commercially
                    acceptable means to protect your personal information, we
                    cannot guarantee its absolute security.
                </p>

                <h2 style={styles.sectionTitle}>4. Information Sharing</h2>
                <p style={styles.text}>
                    We do not sell, trade, or otherwise transfer your personal
                    information to outside parties without your consent, except
                    to provide you with the services you have requested or as
                    required by law.
                </p>

                <h2 style={styles.sectionTitle}>
                    5. Cookies and Tracking Technologies
                </h2>
                <p style={styles.text}>
                    Our website may use cookies and similar tracking
                    technologies to enhance user experience and analyze website
                    traffic. You can choose to accept or decline cookies. Most
                    web browsers automatically accept cookies, but you can
                    modify your browser setting to decline cookies if you
                    prefer.
                </p>

                <h2 style={styles.sectionTitle}>6. Your Rights</h2>
                <p style={styles.text}>You have the right to:</p>
                <ul style={styles.text}>
                    <li>Access the personal information we hold about you</li>
                    <li>
                        Request correction of any inaccuracies in your personal
                        information
                    </li>
                    <li>
                        Request deletion of your personal information, subject
                        to certain exceptions
                    </li>
                </ul>

                <h2 style={styles.sectionTitle}>
                    7. Changes to This Privacy Policy
                </h2>
                <p style={styles.text}>
                    We may update this Privacy Policy from time to time. We will
                    notify you of any changes by posting the new Privacy Policy
                    on this page. Your continued use of the website after any
                    changes signifies your acceptance of the new Privacy Policy.
                </p>

                <h2 style={styles.sectionTitle}>8. Contact Us</h2>
                <p style={styles.text}>
                    If you have any questions or concerns about this Privacy
                    Policy or our practices regarding your personal information,
                    please contact us at:
                </p>
                <p style={styles.text}>Email: info@digital-theory.com</p>
            </div>
        </div>
    );
};

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        backgroundColor: 'var(--primary-color)',
    },
    content: {
        maxWidth: '800px',
        padding: 'var(--spacing)',
    },
    title: {
        fontSize: '32px',
        fontWeight: 'bold',
        color: 'var(--primary-text)',
    },
    text: {
        fontSize: '16px',
        color: 'var(--secondary-color)',
    },
    effectiveDate: {
        fontSize: '14px',
        color: '#777',
    },
    sectionTitle: {
        fontSize: '24px',
        fontWeight: 'bold',
        marginTop: '20px',
        color: 'var(--primary-text)',
    },
};

export default PrivacyPolicy;
