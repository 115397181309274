import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Nav from './components/nav/index.jsx';
import Footer from './components/footer/index.jsx';

import Home from './pages/home/index.jsx';
import Legal from './pages/legal/index.jsx';
import Terms from './pages/terms/index.jsx';
import Privacy from './pages/privacy/index.jsx';


import ScrollToSection from './services/scrollToSection/index.jsx';

import './App.css';

function App() {

  return (
    <Router>
      <div id='app' className='d-flex flex-column' style={{height:'auto'}}>
        <Nav />
        <ScrollToSection />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<Home />} />
          {/* Uncomment the line below if you want to enable the Legal page */}
          <Route path='/legal' element={<Legal />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/privacy' element={<Privacy />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;