import React, { useState } from 'react';
import { motion } from 'framer-motion';

const SERVER = process.env.SERVER_URL;

const ContactForm = () => {
    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        email: '',
        phone: '',
        message: '',
    });
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    // Handle input changes
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const isFormDataEmpty = () => {
        return Object.values(formData).some((value) => value === '');
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission

        setFormSubmitted(true); // Set the formSubmitted state to true

        // Check if any form field is empty
        if (isFormDataEmpty()) {
            return; // Stop form submission if any field is empty
        }

        try {
            const response = await fetch('/api/sendEmail', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    origin: window.location.origin,
                },
                body: JSON.stringify(formData), // Convert the form data to JSON string
            });

            // Check if the response is not OK
            if (!response.ok) {
                const errorData = await response.json(); // Assuming the API returns JSON
                // Use the error message from the server
                throw new Error(
                    errorData.errors
                        ? errorData.errors[0].msg
                        : 'Something went wrong'
                );
            }

            // Handle success response
            const result = await response.json(); // Assuming the API returns JSON
            setEmailSent(true);
        } catch (error) {
            console.error('Error:', error);
            // Display a generic error message for client-side issues or the specific server error
            alert(`Error: ${error.message}`);
        }
    };

    return (
        <div id="contact" style={styles.container}>
            <div style={styles.leftSection}>
                <h2 style={styles.title}>Contact Us</h2>
                <p style={styles.text}>
                    Email, call, or complete the form to learn how we can solve
                    your problems.
                </p>
                <p style={styles.contactInfo}>info@digital-theory.com</p>
                <p style={styles.contactInfo}>+1 (857) 239-0859</p>
            </div>
            <motion.div
                style={styles.rightSection}
                animate={{ backgroundColor: emailSent ? '#4B564F' : 'none' }}
            >
                <motion.h2
                    animate={{ opacity: emailSent ? 0 : 1 }}
                    style={styles.formTitle}
                >
                    Get in Touch
                </motion.h2>
                <motion.p
                    animate={{ opacity: emailSent ? 0 : 1 }}
                    style={styles.formSubtitle}
                >
                    You can reach us anytime
                </motion.p>
                <motion.div
                    animate={{ opacity: emailSent ? 1 : 0 }}
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        right: '0',
                        bottom: '0',
                    }}
                >
                    <h2 style={{ color: 'var(--primary-text)' }}>
                        Thanks for reaching out!
                    </h2>
                    <p style={{ color: 'var(--primary-text)' }}>
                        Someone will reach out to you shortly
                    </p>
                </motion.div>
                <motion.form
                    style={styles.form}
                    onSubmit={handleSubmit}
                    animate={{ opacity: emailSent ? 0 : 1 }}
                >
                    <div style={styles.row}>
                        <input
                            type="text"
                            name="fname"
                            placeholder="First name"
                            style={{
                                ...styles.input,
                                border:
                                    formSubmitted && !formData.fname
                                        ? '3px solid red'
                                        : '1px solid #ccc',
                            }}
                            onChange={handleChange}
                        />
                        <input
                            type="text"
                            name="lname"
                            placeholder="Last name"
                            style={{
                                ...styles.input,
                                border:
                                    formSubmitted && !formData.lname
                                        ? '3px solid red'
                                        : '1px solid #ccc',
                            }}
                            onChange={handleChange}
                        />
                    </div>
                    <input
                        type="email"
                        name="email"
                        placeholder="Your email"
                        style={{
                            ...styles.inputFull,
                            border:
                                formSubmitted && !formData.email
                                    ? '3px solid red'
                                    : '1px solid #ccc',
                        }}
                        onChange={handleChange}
                    />
                    <div style={styles.row}>
                        {/* <select style={styles.input}>
                            <option value="+1">+1</option>
                        </select> */}
                        <input
                            type="tel"
                            name="phone"
                            placeholder="Phone number"
                            style={{
                                ...styles.input,
                                border:
                                    formSubmitted && !formData.phone
                                        ? '3px solid red'
                                        : '1px solid #ccc',
                            }}
                            onChange={handleChange}
                        />
                    </div>
                    <textarea
                        placeholder="How can we help?"
                        name="message"
                        maxLength="120"
                        style={{
                            ...styles.textarea,
                            border:
                                formSubmitted && !formData.message
                                    ? '3px solid red'
                                    : '1px solid #ccc',
                        }}
                        onChange={handleChange}
                    />
                    <button type="submit" style={styles.submitButton}>
                        Submit
                    </button>
                    <p style={styles.terms}>
                        By contacting us, you agree to our{' '}
                        <a href="/terms" style={styles.link}>
                            Terms of service
                        </a>{' '}
                        and{' '}
                        <a href="/privacy" style={styles.link}>
                            Privacy Policy
                        </a>
                        .
                    </p>
                </motion.form>
            </motion.div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '40px',
        backgroundColor: 'var(--primary-color)',
        flexWrap: 'wrap',
    },
    leftSection: {
        flex: '1',
        marginRight: '20px',
        maxWidth: '50%',
        minWidth: '250px',
        paddingBottom: '20px',
    },
    rightSection: {
        position: 'relative',
        flex: '1',
        backgroundColor: 'var(--secondary-color)',
        padding: '40px',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        maxWidth: '100%',
    },
    title: {
        fontSize: '36px',
        width: '100%',
        fontWeight: '700',
        paddingTop: 'var(--spacing)',
        color: 'var(--primary-text)',
    },
    text: {
        fontSize: '18px',
        width: '100%',
        lineHeight: '1.6',
        color: 'var(--secondary-color)',
    },
    contactInfo: {
        fontSize: '18px',
        fontWeight: '500',
        margin: '10px 0',
        color: '#FFFFFF',
    },
    link: {
        color: '#007bff',
        textDecoration: 'none',
        fontSize: '16px',
        fontWeight: '500',
    },
    formTitle: {
        fontSize: '28px',
        fontWeight: '600',
        marginBottom: '10px',
    },
    formSubtitle: {
        fontSize: '16px',
        color: '#666',
        marginBottom: '30px',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        gap: '20px',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginBottom: '20px',
    },
    input: {
        flex: '1',
        padding: '12px',
        fontSize: '16px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        minWidth: '140px',
        backgroundColor: 'var(--primary-color)',
        color: 'var(--primary-text)',
    },
    inputFull: {
        width: '100%',
        padding: '12px',
        fontSize: '16px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        marginBottom: '20px',
        backgroundColor: 'var(--primary-color)',
        color: 'var(--primary-text)',
    },
    textarea: {
        width: '100%',
        padding: '12px',
        fontSize: '16px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        minHeight: '100px',
        marginBottom: '20px',
        backgroundColor: 'var(--primary-color)',
        color: 'var(--primary-text)',
    },
    submitButton: {
        backgroundColor: '#424641',
        color: '#fff',
        padding: '12px',
        fontSize: '16px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    terms: {
        fontSize: '14px',
        color: '#666',
        marginTop: '20px',
    },
    // Responsive Styles
    '@media (max-width: 768px)': {
        container: {
            flexDirection: 'column',
        },
        leftSection: {
            maxWidth: '100%',
            marginRight: '0',
            textAlign: 'center',
        },
        rightSection: {
            maxWidth: '100%',
        },
        row: {
            flexDirection: 'column',
        },
        input: {
            marginRight: '0',
            marginBottom: '10px',
        },
    },
};

export default ContactForm;
