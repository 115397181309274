import React from 'react';

export default function Hero() {
    const styles = {
        hero: {
            backgroundColor: 'var(--primary-color)',
            color: 'var(--primary-text)',
            height: 'auto',
            width: '100%',
            overflowX: 'hidden',
            padding: 'var(--spacing) 0',
            hero_title_row: {
                overflowX: 'visible',
                width: '100%',
                height: '100%',
                maxWidth: '1400px',
                hero_title_col: {
                    width: '50%',
                    minWidth: '300px',
                    position: 'relative',
                    overflowX: 'visible',
                    margin: '0 var(--spacing) 0 var(--spacing)',
                },
                hero_picture_col: {
                    position: 'relative',
                    height: '75%',
                    width: '75%',
                    minWidth: '250px',
                    overflow: 'visible',
                },
            },
            hero_subtitle_row: {
                width: '100%',
                h2: {
                    width: '100%',
                    fontFamily: 'var(--primary-font)',
                    margin: '30px var(--spacing) 0 15px',
                    whiteSpace: 'nowrap',
                },
            },
        },
    };

    return (
        <div
            id="home"
            className="d-flex flex-column justify-content-center align-items-center"
            style={styles.hero}
        >
            <div
                id="hero-title-row"
                className="d-flex align-items-center flex-md-row flex-column"
                style={styles.hero.hero_title_row}
            >
                <div
                    id="hero-title-col"
                    className="d-flex flex-column"
                    style={styles.hero.hero_title_row.hero_title_col}
                >
                    <h1 style={styles.hero.hero_title_row.hero_title_col.h1}>
                        Digital
                    </h1>
                    <h1 style={styles.hero.hero_title_row.hero_title_col.h1}>
                        Theory
                    </h1>
                    <h2
                        className="archivo-100"
                        style={styles.hero.hero_subtitle_row.h2}
                    >
                        A digital consultancy
                    </h2>
                    <h2
                        className="archivo-100"
                        style={styles.hero.hero_subtitle_row.h2}
                    >
                        that helps business owners
                    </h2>
                    <h2
                        className="archivo-100"
                        style={styles.hero.hero_subtitle_row.h2}
                    >
                        scale their business online
                    </h2>
                    <div id="hero-title-shadow"></div>
                </div>
                <div
                    className="d-flex align-items-center justify-content-center"
                    id="hero-picture-col"
                    style={styles.hero.hero_title_row.hero_picture_col}
                >
                    <img
                        className="hero-picture aspect-ratio-box"
                        src="/components/photos/phones.jpg"
                        alt="phone"
                    />
                </div>
            </div>
        </div>
    );
}
