import React from 'react';
import classes from './index.module.css';

const services = {
    items: [
        'Consulting',
        'Design',
        'Development',
        'Analytics',
        'Marketing',
        'Branding',
    ],

    captions: [
        'We provide consulting services to help you make the right decisions.',
        'We design websites, and other digital solutions.',
        'We develop fully functional websites and use premier current technologies.',
        'We provide analytics services to help you understand your data.',
        'We provide marketing services to help you reach your audience.',
        'We provide branding services to help you stand out.',
    ],
};

export default function our_services() {
    return (
        <div
            id="services"
            className={`d-flex flex-column align-items-center justify-content-center ${classes.container}`}
        >
            <div
                className={`w-100 d-flex justify-content-center ${classes.titleRow}`}
            >
                <h2 className={`archivo-600 ${classes.title}`}>Services</h2>
            </div>
            <div
                className={`d-flex flex-wrap justify-content-center ${classes.content}`}
            >
                {services.items.map((item, index) => (
                    <div
                        key={item}
                        className={`d-flex flex-column ${classes.item} justify-content-evenly`}
                    >
                        <img
                            className={`${classes.logos}`}
                            src={`/components/icons/${item.toLowerCase()}.svg`}
                            alt={`${item} logo`}
                        />
                        <h3>{item}</h3>
                        <h5 className={`${classes.caption} my-auto`}>
                            {services.captions[index]}
                        </h5>
                    </div>
                ))}
            </div>
        </div>
    );
}
