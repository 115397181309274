import React from 'react';

import Hero from './hero/index.jsx';
import AreYou from './are_you/index.jsx';
import WhatWeDo from './what_we_do/index.jsx';
import Services from './our_services/index.jsx';
import ContactUs from './contact_us/index.jsx';

export default function Home() {
    return (
        <div id="home">
            <Hero />
            <AreYou />
            <WhatWeDo />
            <Services />
            <ContactUs />
        </div>
    );
}
