import React from 'react';

const LegalDisclaimer = () => {
    return (
        <div className="w-100" style={styles.container}>
            <div className="content d-flex flex-column" style={styles.content}>
                <h1 style={styles.header}>Legal Disclaimer</h1>

                <h2 style={styles.subheader}>Introduction</h2>
                <p className="archivio-400" style={styles.paragraph}>
                    The information provided by <strong>Digital Theory</strong>{' '}
                    ("we," "us," or "our") on
                    <strong> www.digital-theory.com </strong> (the "Site") is
                    for general informational purposes only. All information on
                    the Site is provided in good faith, however, we make no
                    representation or warranty of any kind, express or implied,
                    regarding the accuracy, adequacy, validity, reliability,
                    availability, or completeness of any information on the
                    Site.
                </p>

                <h2 style={styles.subheader}>No Professional Advice</h2>
                <p className="archivio-400" style={styles.paragraph}>
                    The information provided on this website is not intended to,
                    and does not, constitute legal, financial, or professional
                    advice; instead, all information, content, and materials
                    available on this site are for general informational
                    purposes only. <strong>Digital Theory</strong> does not
                    provide legal or financial services. You should consult with
                    a qualified professional before making any decisions based
                    on the information provided on this Site.
                </p>

                <h2 style={styles.subheader}>External Links Disclaimer</h2>
                <p className="archivio-400" style={styles.paragraph}>
                    The Site may contain (or you may be sent through the Site)
                    links to other websites or content belonging to or
                    originating from third parties or links to websites and
                    features in banners or other advertising. Such external
                    links are not investigated, monitored, or checked for
                    accuracy, adequacy, validity, reliability, availability, or
                    completeness by us. We do not warrant, endorse, guarantee,
                    or assume responsibility for the accuracy or reliability of
                    any information offered by third-party websites linked
                    through the site or any website or feature linked in any
                    banner or other advertising.
                </p>

                <h2 style={styles.subheader}>Limitation of Liability</h2>
                <p className="archivio-400" style={styles.paragraph}>
                    In no event shall <strong>Digital Theory</strong>, nor its
                    directors, employees, partners, agents, suppliers, or
                    affiliates, be liable for any indirect, incidental, special,
                    consequential, or punitive damages, including without
                    limitation, loss of profits, data, use, goodwill, or other
                    intangible losses, resulting from (i) your access to or use
                    of or inability to access or use the Site; (ii) any
                    unauthorized access, use, or alteration of your
                    transmissions or content; or (iii) any other matter related
                    to the Site.
                </p>

                <h2 style={styles.subheader}>Changes to This Disclaimer</h2>
                <p className="archivio-400" style={styles.paragraph}>
                    We may update our Disclaimer from time to time in order to
                    reflect changes to our practices or for other operational,
                    legal, or regulatory reasons.
                </p>
            </div>
        </div>
    );
};

// Styles
const styles = {
    container: {
        backgroundColor: 'var(--primary-color)',
        padding: '20px',
        borderRadius: '5px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Arial, sans-serif',
        padding: '20px',
        gap: '10px',
    },
    content: {
        padding: 'var(--spacing)',
    },
    header: {
        color: 'var(--primary-text)',
        fontSize: '44px',
    },
    subheader: {
        marginTop: '20px',
        color: 'var(--primary-text)',
    },
    paragraph: {
        fontSize: '16px',
        lineHeight: '1.6',
        color: 'var(--primary-text)',
    },
};

export default LegalDisclaimer;
