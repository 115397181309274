import React from 'react';

const TermsOfService = () => {
    return (
        <div style={styles.container}>
            <div className="d-flex flex-column" style={styles.content}>
                <h1 style={styles.title}>Terms of Service</h1>
                <p style={styles.effectiveDate}>Effective Date: 10/2/2024</p>
                <p style={styles.intro}>
                    Thank you for your interest in Digital Theory. By using our
                    contact form to request information, you agree to the
                    following Terms of Service.
                </p>

                <h2 style={styles.sectionTitle}>1. Acceptance of Terms</h2>
                <p>
                    By submitting the contact form, you agree to comply with and
                    be bound by these Terms of Service. If you do not agree with
                    any part of these terms, please do not use the contact form.
                </p>

                <h2 style={styles.sectionTitle}>2. Information Collection</h2>
                <p>
                    When you fill out our contact form, we collect personal
                    information, including but not limited to your name, email
                    address, phone number, and any other information you
                    provide. We use this information to respond to your
                    inquiries and to improve our services.
                </p>

                <h2 style={styles.sectionTitle}>3. Use of Information</h2>
                <p>We may use your information to:</p>
                <ul style={styles.list}>
                    <li>
                        Respond to your inquiries and provide you with requested
                        information about our services.
                    </li>
                    <li>Improve our website and customer service.</li>
                    <li>
                        Send periodic emails regarding your inquiry or other
                        products and services.
                    </li>
                </ul>

                <h2 style={styles.sectionTitle}>
                    4. Data Protection and Privacy
                </h2>
                <p>
                    We are committed to protecting your privacy. Your personal
                    information will not be sold, traded, or otherwise
                    transferred to outside parties without your consent, except
                    as required by law. For more information, please review our
                    Privacy Policy.
                </p>

                <h2 style={styles.sectionTitle}>5. User Responsibilities</h2>
                <p>When using the contact form, you agree to:</p>
                <ul style={styles.list}>
                    <li>Provide accurate and current information.</li>
                    <li>Use the form for lawful purposes only.</li>
                    <li>
                        Not impersonate any person or entity or misrepresent
                        your affiliation with any person or entity.
                    </li>
                </ul>

                <h2 style={styles.sectionTitle}>6. Limitation of Liability</h2>
                <p>
                    In no event shall Digital Theory, its officers, directors,
                    employees, or agents be liable for any indirect, incidental,
                    special, consequential, or punitive damages arising from
                    your use of the contact form or any information provided
                    therein.
                </p>

                <h2 style={styles.sectionTitle}>7. Changes to These Terms</h2>
                <p>
                    We reserve the right to update or modify these Terms of
                    Service at any time without prior notice. Your continued use
                    of the contact form after any changes signifies your
                    acceptance of the new Terms.
                </p>

                <h2 style={styles.sectionTitle}>8. Governing Law</h2>
                <p>
                    These Terms of Service shall be governed by and construed in
                    accordance with the laws of The United States of America.
                    Any disputes arising under or in connection with these terms
                    shall be subject to the exclusive jurisdiction of the courts
                    located in the state of New Hampshire.
                </p>

                <h2 style={styles.sectionTitle}>9. Contact Information</h2>
                <p>
                    If you have any questions about these Terms of Service,
                    please contact us at:
                </p>
                <p>Email: info@digital-theory.com</p>
            </div>
        </div>
    );
};

const styles = {
    container: {
        backgroundColor: 'var(--primary-color)',
        color: 'var(--primary-text)',
    },
    content: {
        padding: 'var(--spacing)',
        gap: '20px',
    },
    title: {
        fontSize: '36px',
        fontWeight: '700',
    },
    effectiveDate: {
        fontSize: '16px',
        marginBottom: '20px',
    },
    sectionTitle: {
        fontSize: '28px',
        fontWeight: '600',
        marginTop: '20px',
    },
    list: {
        marginLeft: '20px',
        marginBottom: '20px',
    },
};

export default TermsOfService;
