import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';

async function initApp() {
    // await verifyApp();  // Run verifyApp before rendering
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(<App />);
}

initApp();  // Initialize app
