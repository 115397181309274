import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
    const footer = {
        footer: {
            backgroundColor: 'var(--primary-color)',
            width: '100%',
            color: 'var(--primary-text)',
            borderTop: '.1px solid var(--primary-text)',
            overflowX: 'hidden',
            padding: 'var(--spacing) 0 var(--spacing) 0',
            footer_logo_row: {
                width: '100%',
                margin: '0 var(--spacing) 0 var(--spacing)',
                h2: {
                    fontSize: '2rem',
                    width: '100%',
                },
                p: {
                    fontSize: '1rem',
                    width: '100%',
                },
                footer_socials_row: {
                    width: '100%',
                    minWidth: '250px',
                    height: '80px',
                    socials: {
                        width: '60px',
                        height: '60px',
                        color: 'var(--primary-text)',
                        items: ['linkedin.svg'],
                        itemsLink: [
                            'https://www.linkedin.com/company/digital-theory-llc',
                        ],
                    },
                },
            },
            footer_links_row: {
                items: ['about', 'services', 'contact'],
                margin: '0 var(--spacing) 0 var(--spacing)',
                p: {
                    textDecoration: 'none',
                    color: 'var(--primary-text)',
                },
            },
            footer_compliance_row: {
                margin: '0 var(--spacing) 0 var(--spacing)',
                p: {
                    textDecoration: 'none',
                    color: 'var(--secondary-subtext)',
                },
            },
        },
    };

    return (
        <footer
            id="footer"
            className="d-flex flex-column mt-auto"
            style={footer.footer}
        >
            <div
                id="footer-logo-row"
                className="d-flex flex-column"
                style={footer.footer.footer_logo_row}
            >
                <h2 style={footer.footer.footer_logo_row.h2}>DigitalTheory</h2>
                <p style={footer.footer.footer_logo_row.p}>
                    Scaling your business
                </p>
                <div
                    id="footer-socials-row"
                    className="d-flex"
                    style={footer.footer.footer_logo_row.footer_socials_row}
                >
                    {footer.footer.footer_logo_row.footer_socials_row.socials.items.map(
                        (item, index) => {
                            return (
                                <Link
                                    to={`${footer.footer.footer_logo_row.footer_socials_row.socials.itemsLink[index]}`}
                                    key={item}
                                >
                                    <div
                                        id={item}
                                        key={item}
                                        style={
                                            footer.footer.footer_logo_row
                                                .footer_socials_row.socials
                                        }
                                    >
                                        <img
                                            src={`./components/icons/${item}`}
                                            key={`footer-socials-${item}`}
                                            alt={item}
                                            style={footer.socials}
                                        />
                                    </div>
                                </Link>
                            );
                        }
                    )}
                </div>
            </div>
            <div id="footer-links-row" style={footer.footer.footer_links_row}>
                {footer.footer.footer_links_row.items.map((item, index) => {
                    return (
                        <Link
                            key={`footer-links-${item}`}
                            to={`/#${item}`}
                            style={footer.footer.footer_links_row.p}
                        >
                            <p
                                key={index}
                                style={footer.footer.footer_links_row.p}
                            >
                                {item}
                            </p>
                        </Link>
                    );
                })}
            </div>
            <div
                id="footer-compliance-row"
                className="d-flex justify-content-between"
                style={footer.footer.footer_compliance_row}
            >
                <Link to="/Legal" style={footer.footer.footer_compliance_row.p}>
                    Legal
                </Link>
                <p style={footer.footer.footer_compliance_row.p}>
                    DigitalTheory LLC, All Rights Reserved
                </p>
            </div>
        </footer>
    );
}
