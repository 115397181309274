import React from 'react';
import classes from './index.module.css';
import Typewriter from 'typewriter-effect';

import Contact from '../contact/index.jsx';

export default function WhatWeDo() {
    return (
        <div
            id="about"
            className={`d-flex flex-column align-items-center ${classes.container}`}
        >
            <div
                id="whoWeAre-content"
                className={`d-flex flex-wrap justify-content-between align-items-center w-100 ${classes.content}`}
            >
                <div className="d-flex flex-grow-1 justify-content-center">
                    <div
                        id="whoWeAre-title"
                        className={`d-flex flex-column ${classes.title}`}
                    >
                        <h2 className={`archivo-600 ${classes.titleText}`}>
                            We Make
                        </h2>
                        <div
                            id="whoWeAre-title-impact"
                            className={`${classes.titleImpact}`}
                        >
                            <h2
                                className={`archivo-600 ${classes.titleImpactText}`}
                            >
                                <Typewriter
                                    options={{
                                        strings: [
                                            'Professional',
                                            'Reliable',
                                            'Elegant',
                                        ],
                                        delay: 50,
                                        pauseFor: 1000,
                                        autoStart: true,
                                        loop: true,
                                    }}
                                />
                            </h2>
                        </div>
                        <h2 className={`archivo-600 ${classes.titleText}`}>
                            Digital Solutions
                        </h2>
                    </div>
                </div>
                <div className="d-flex flex-grow-1 justify-content-center">
                    {/* <Contact /> */}
                    {/* <h3 className="w-100" style={{ textAlign: 'center' }}>
                        We collaborate with businesses <br />
                        to find digital solutions <br />
                        that your customers will love
                    </h3> */}
                    <img
                        src="/components/photos/iphone.png"
                        alt="iphone"
                        className={`${classes.iphone}`}
                    />
                </div>
            </div>
        </div>
    );
}
